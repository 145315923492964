import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { fetchNews } from "@/api/news";

type NewsItemRaw = {
  title: string;
  excerpt: string | null;
  url: string;
  publish_date: { timestamp: number; "d-m-y": string; "m-d-y": string };
  categories: {
    id: number;
    slug: string;
    name: string;
  }[];
};

export type NewsItem = {
  id: string;
  title: string;
  excerpt: string;
  url: string;
  date: Date;
};

type NewsRaw = Record<string, NewsItemRaw>;

export const useNews = defineStore("news", () => {
  const news = ref<NewsRaw>({});

  const fetch = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      news.value = await (await fetchNews()).json();
    } catch (error) {
      console.error(`Failed to fetch news:`, error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetch();

  const newsForCategories = (categorySlugs: string[] = []) => {
    return computed<NewsItem[]>(() => {
      return (
        Object.entries(news.value)
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .filter(([_key, value]) => {
            const itemSlugs = value.categories.map((c) => c.slug);
            return (
              itemSlugs.includes("wof") && categorySlugs.some((slug) => value.categories.some((c) => c.slug === slug))
            );
          })
          .map(([key, value]) => ({
            id: key,
            title: value.title,
            excerpt: value.excerpt ?? "",
            url: value.url,
            date: new Date(value.publish_date.timestamp),
          }))
          .sort((a, b) => b.date.getTime() - a.date.getTime())
      );
    });
  };

  return {
    fetch,
    newsForCategories,
  };
});
