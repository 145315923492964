/*
structure:

          overview
     ┌────────────────┐
     │                │
building1          building2
           ┌───────────────┐
           │               │
         line1           line2
              ┌──────────── ◄──────┐
              ▼                    │
          station1──►station2──►station3

HINT: stations also have parent<->child edges, but these are not considered in the flow if their `video` is null
*/
import "./00_overview";
import "./01_performance";
import "./02_flexibility";
import "./03_2pieceCan";
import "./04_endMaking";
import "./05_classics";

import { graphBuilder } from "@/stores/navigation/graphBuilder";

const { graph } = graphBuilder;

export { graph };
