import intro_video from "@/assets/media/00_overview/overview.mp4?url";
import overview_still from "@/assets/media/00_overview/overview.png?url";
import { graphBuilder } from "@/stores/navigation/graphBuilder";

const { addOverview } = graphBuilder;

export const overview = addOverview(
  {
    id: "overview",
    label: "overview",
    still: overview_still,
  },
  intro_video,
);
