import type { NewsItem } from "@/stores/news";

import type { Supplier } from "./suppliers";

export enum NodeType {
  OVERVIEW = "overview",
  BUILDING = "building",
  STATION = "station",
}

export enum EdgeDirection {
  UP,
  DOWN,
  PREVIOUS,
  NEXT,
}

export interface Department {
  id: string;
  label: string;
  description: string;
  url: string;
}

export interface Machine {
  label: string;
  description: string;
  buttonData?: ButtonPosData;
  suppliers: { supplier: Supplier; url: string }[];
  newsCategorySlugs?: string[];
}

export interface BaseNode {
  id: string;
  label?: string;
  still?: string;
  newsCategorySlugs?: string[];
}

export type Vec = [number, number];

export type ButtonPosData = {
  boxPos: Vec;
  targetPos: Vec;
};

export type DepartmentButton = Department & { buttonData: ButtonPosData };

export type GraphNode = OverviewNode | BuildingNode | StationNode;

export type EnrichedNode = OverviewNode | EnrichedBuildingNode | EnrichedStationNode;

export interface OverviewNode extends BaseNode {
  nodeType: NodeType.OVERVIEW;
}

export type News = { news: NewsItem[] };

export interface BuildingNode extends BaseNode {
  nodeType: NodeType.BUILDING;
  info?: string;
  description?: string;
  departments?: DepartmentButton[];
  buttonData?: ButtonPosData;
  machines?: Machine[];
  lines: LineData[];
}

export type EnrichedMachine = Machine & {
  news: NewsItem[];
};

export type EnrichedBuildingNode = BuildingNode &
  News & {
    lines: (LineData & News)[];
    machines?: EnrichedMachine[];
  };

export enum LineDirection {
  LEFT_TO_RIGHT,
  RIGHT_TO_LEFT,
}

export interface LineData {
  id: string;
  label: string;
  description?: string;
  buttonData?: ButtonPosData;
  firstStationId: string;
  lineDirection?: LineDirection;
}

export interface StationNode extends BaseNode {
  nodeType: NodeType.STATION;
  lineId: string;
  description?: string;
  link?: string;
  subStations?: SubStation[];
}

export type SubStation = {
  id: string;
  label: string;
  description?: string;
  dotPos: Vec;
  link?: string;
  newsCategorySlugs?: string[];
};

export type EnrichedSubStation = SubStation & News;

export type EnrichedStationNode = StationNode &
  News & {
    subStations?: EnrichedSubStation[];
  };

export interface Edge {
  direction: EdgeDirection;
  video: string | null;
  playbackRate?: number;
}
