import { DirectedGraph } from "graphology";

import { assertIsDefined } from "@/core/utils/typeHelpers";
import { overview } from "@/stores/navigation/graph/00_overview";
import { factoryMedia } from "@/stores/navigation/graph/media";
import type { BaseNode, BuildingNode, Edge, LineData, OverviewNode, StationNode } from "@/stores/navigation/nodes";
import { EdgeDirection, NodeType } from "@/stores/navigation/nodes";

const createGraphBuilder = () => {
  const graph = new DirectedGraph();

  const addOverview = (node: Omit<OverviewNode, "nodeType">, video: string) => {
    const newNode: OverviewNode = { ...node, nodeType: NodeType.OVERVIEW };
    graph.addNode(newNode.id, newNode);

    const introNode: BaseNode = { id: "intro", label: "", still: "" };
    graph.addNode("intro", introNode);
    const introEdge: Edge = {
      video,
      direction: EdgeDirection.DOWN,
    };
    graph.addDirectedEdge("intro", newNode.id, introEdge);
    return newNode.id;
  };

  const addBuilding = (node: Omit<BuildingNode, "still" | "lines" | "nodeType">, mediaName: string) => {
    const buildingNode: BuildingNode = { ...node, nodeType: NodeType.BUILDING, lines: [] };

    type StationData = {
      node: StationNode;
      mediaName?: string;
    };
    type Line = {
      data: Omit<LineData, "firstStationId">;
      mediaDir: string;
      stations: StationData[];
    };

    const builderData: {
      mediaName: string;
      lines: Line[];
    } = {
      mediaName,
      lines: [],
    };

    const addLine = (lineData: Omit<LineData, "firstStationId">, mediaDir: string) => {
      const line: Line = {
        data: lineData,
        mediaDir,
        stations: [],
      };
      builderData.lines.push(line);

      const addStation = (station: Omit<StationNode, "still" | "lineId" | "nodeType">, mediaName?: string) => {
        const stationNode: StationNode = { ...station, lineId: lineData.id, nodeType: NodeType.STATION };
        line.stations.push({
          node: stationNode,
          mediaName,
        });
        return lineBuilder;
      };

      const lineBuilder = {
        line: lineData,
        addStation,
      };

      return lineBuilder;
    };

    const build = () => {
      const buildingMedia = factoryMedia(builderData.mediaName);

      graph.addNode(buildingNode.id, { ...buildingNode, still: buildingMedia.image(builderData.mediaName) });

      addParentChildEdge(
        overview,
        buildingNode.id,
        {
          video: buildingMedia.video(builderData.mediaName),
        },
        {
          video: buildingMedia.video(`${builderData.mediaName}_reversed`),
          playbackRate: 2,
        },
      );

      buildingNode.lines = builderData.lines.map((l) => {
        const lineMedia = buildingMedia.line(l.mediaDir);

        l.stations.forEach((station, i) => {
          const mediaName = station.mediaName;
          const stationNode: StationNode = { ...station.node, still: lineMedia.image(mediaName) ?? undefined };
          graph.addNode(station.node.id, stationNode);

          if (i === 0) {
            addParentChildEdge(
              buildingNode.id,
              station.node.id,
              { video: lineMedia.video(mediaName) },
              { video: lineMedia.video(mediaName && `${mediaName}_reversed`), playbackRate: 1.5 },
            );
          } else {
            addParentChildEdge(buildingNode.id, station.node.id);

            const prevStation = l.stations[i - 1];
            assertIsDefined(prevStation);
            addSiblingEdge(
              prevStation.node.id,
              station.node.id,
              { video: lineMedia.video(mediaName) },
              { video: lineMedia.video(mediaName && `${mediaName}_reversed`) },
            );
          }
        });

        const firstStationId = l.stations[0]?.node.id;
        assertIsDefined(firstStationId);

        return {
          ...l.data,
          firstStationId,
        };
      });

      graph.updateNodeAttributes(buildingNode.id, (attrs) => ({
        ...attrs,
        lines: buildingNode.lines,
      }));
    };

    const buildingBuilder = {
      buildingNode,
      addLine,
      build,
    };

    return buildingBuilder;
  };

  const addParentChildEdge = (
    parentId: string,
    childId: string,
    downEdge?: Omit<Edge, "direction">,
    upEdge?: Omit<Edge, "direction">,
  ) => {
    graph.addDirectedEdge(parentId, childId, { ...downEdge, direction: EdgeDirection.DOWN });
    graph.addDirectedEdge(childId, parentId, { ...upEdge, direction: EdgeDirection.UP });
  };

  const addSiblingEdge = (
    previousId: string,
    nextId: string,
    nextEdge?: Omit<Edge, "direction">,
    prevEdge?: Omit<Edge, "direction">,
  ) => {
    graph.addDirectedEdge(previousId, nextId, { ...nextEdge, direction: EdgeDirection.NEXT });
    graph.addDirectedEdge(nextId, previousId, { ...prevEdge, direction: EdgeDirection.PREVIOUS });
  };

  return {
    graph,
    addOverview,
    addBuilding,
  };
};

export const graphBuilder = createGraphBuilder();
