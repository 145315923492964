import { gettext } from "@/core/translations";
import { laboratory, premiumConversions, training, upgrades } from "@/stores/navigation/graph/departments";
import { graphBuilder } from "@/stores/navigation/graphBuilder";

const { $gettext } = gettext;

const { addBuilding } = graphBuilder;

const twoPieceCan = addBuilding(
  {
    id: "twoPieceCan",
    label: $gettext("2-Piece Cans"),
    departments: [
      {
        ...training,
        buttonData: {
          boxPos: [0.4528601694915254, 0.263653483992467],
          targetPos: [0.4528601694915254, 0.448210922787194],
        },
      },
      {
        ...laboratory,
        buttonData: {
          boxPos: [0.5926906779661016, 0.3757062146892655],
          targetPos: [0.5926906779661016, 0.4576271186440678],
        },
      },
      {
        ...upgrades,
        buttonData: {
          boxPos: [0.6800847457627118, 0.263653483992467],
          targetPos: [0.6800847457627118, 0.4642184557438795],
        },
      },
      {
        ...premiumConversions,
        buttonData: {
          boxPos: [0.7500847457627118, 0.3757062146892655],
          targetPos: [0.7500847457627118, 0.4742184557438795],
        },
      },
    ],
    buttonData: {
      boxPos: [0.021932424422050976, 0.03899097675031285],
      targetPos: [0.24069767441860465, 0.21912144702842376],
    },
    info: $gettext("1,800 cans per minute"),
    description: $gettext("Production lines for DRD round or shaped canbodies. Processing of DWI cans."),
    newsCategorySlugs: ["2-piece-cans"],
  },
  "03_2pieceCan",
);

// 2-PIECE CAN PRESS line
twoPieceCan
  .addLine(
    {
      id: "2pc_press",
      label: $gettext("2-Piece Can Press"),
      buttonData: {
        boxPos: [0.4242584745762712, 0.6572504708097928],
        targetPos: [0.6954449152542372, 0.6365348399246704],
      },
      description: $gettext(`
UNIPRESS/UNIPRESS RPT
- High-speed multi-die press for deep-drawn cans
- Round, conical, rectangular or irregularly shaped cans
- 80 - 200 strokes/min
- Tinplate or Aluminium `),
    },
    "01_2pieceCanPress",
  )
  .addStation(
    {
      id: "01_2pieceCanPressMachine",
      subStations: [
        {
          id: "2pc_multiDiePredrawPress",
          label: $gettext("Multi-Die Predraw Press"),
          description: $gettext("The predraw press is designed to stamp and pre-draw a shell"),
          newsCategorySlugs: ["multi-die-predraw-press"],
          dotPos: [0.5836864406779662, 0.3954802259887006],
          link: "https://soudronic.com/products/2-piece-cans/?filtered%5Bseries%5D%5B%5D=UNIPRESS",
        },
        {
          id: "2pc_multiDieRedrawTrimmingPress",
          label: $gettext("Multi-Die Redraw/Trimming Press"),
          description: $gettext("The redraw and trimming press forms the final shape of the can"),
          newsCategorySlugs: ["multi-die-redraw-trimming-press"],
          dotPos: [0.7627118644067796, 0.3954802259887006],
          link: "https://soudronic.com/products/2-piece-cans/?filtered%5Bseries%5D%5B%5D=UNIPRESS",
        },
      ],
    },
    "01_machine",
  );

// 2-PIECE COMBINATION MACHINE line
twoPieceCan
  .addLine(
    {
      id: "2pc_combinationMachine",
      label: $gettext("2-Piece Combination Machine"),
      description: $gettext(`
CAN-O-MAT 2PC
- Multi-function system for can forming
- Round DWI and DRD food cans
- High level of modularity, 1 - 4 stations
- Necking, flanging, beading, light testing
- Body diameter range 52 - 83 mm
- Height range 60 - 150 mm`),
      buttonData: {
        boxPos: [0.06673728813559322, 0.3465160075329567],
        targetPos: [0.3087923728813559, 0.6195856873822976],
      },
    },
    "02_2pieceCombinationMachine",
  )
  .addStation(
    {
      id: "2pc_twoPieceCombinationMachine",
      label: $gettext("Multi-Function System for Can Forming"),
      description: $gettext("Available stations are necking, flanging, beading, and light testing"),
      newsCategorySlugs: ["multi-function-system-for-can-forming"],
      link: "https://soudronic.com/products/2-piece-cans/?filtered%5Bseries%5D%5B%5D=CAN-O-MAT%202PC",
    },
    "01_machine",
  );

twoPieceCan.build();
