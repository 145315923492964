import { gettext } from "@/core/translations";
import {
  afterSalesService,
  laboratory,
  premiumConversions,
  spareParts,
  training,
  upgrades,
} from "@/stores/navigation/graph/departments";
import { graphBuilder } from "@/stores/navigation/graphBuilder";
import { LineDirection } from "@/stores/navigation/nodes";

const { $gettext } = gettext;

const { addBuilding } = graphBuilder;

const endMaking = addBuilding(
  {
    id: "endMaking",
    label: $gettext("End Making"),
    departments: [
      {
        ...afterSalesService,
        buttonData: {
          boxPos: [0.3172669491525424, 0.3192090395480226],
          targetPos: [0.3172669491525424, 0.4708097928436911],
        },
      },
      {
        ...spareParts,
        buttonData: {
          boxPos: [0.4051906779661017, 0.3757062146892655],
          targetPos: [0.4051906779661017, 0.4736346516007533],
        },
      },
      {
        ...upgrades,
        buttonData: {
          boxPos: [0.5008474576271186, 0.3192090395480226],
          targetPos: [0.5008474576271186, 0.4792843691148776],
        },
      },
      {
        ...premiumConversions,
        buttonData: {
          boxPos: [0.6108474576271186, 0.3757062146892655],
          targetPos: [0.6108474576271186, 0.4792843691148776],
        },
      },
      {
        ...training,
        buttonData: {
          boxPos: [0.7627118644067796, 0.3192090395480226],
          targetPos: [0.7627118644067796, 0.4868173258003766],
        },
      },
      {
        ...laboratory,
        buttonData: {
          boxPos: [0.899364406779661, 0.3757062146892655],
          targetPos: [0.899364406779661, 0.4934086629001883],
        },
      },
    ],
    buttonData: {
      boxPos: [0.3548728813559322, 0.03899097675031285],
      targetPos: [0.5334914048606995, 0.23499967068431799],
    },
    info: $gettext("4,000 ends per minute"),
    description: $gettext("Production lines for ends, shells and POE (Peel-off-ends)"),
    newsCategorySlugs: ["end-making"],
  },
  "04_endMaking",
);

// HEAT SEALING SYSTEM line
endMaking
  .addLine(
    {
      id: "em_heatSealingSystem",
      label: $gettext("Heat Sealing System"),
      description: $gettext(`
UNISEAL
- Multi-lane heat-sealing systems with ring forming module
- Produces composite or aluminum peel-off ends
- Up to 3 lanes, each with max. 200 ends/min.
- End diameter 52 - 189 mm
- Ring material tinplate or aluminium`),
      buttonData: {
        boxPos: [0.0323093220338983, 0.5056497175141242],
        targetPos: [0.3183262711864407, 0.6958568738229756],
      },
    },
    "01_heatSealingSystem",
  )
  .addStation(
    {
      id: "01_heatSealingSystemMachine",
      subStations: [
        {
          id: "em_ringFormingModule",
          label: $gettext("Ring Forming Module"),
          description: $gettext("Forms the shell to a ring through punching, drawing, curling"),
          newsCategorySlugs: ["ring-forming-module"],
          link: "https://soudronic.com/products/peel-off/",
          dotPos: [0.3405720338983051, 0.4764595103578154],
        },
        {
          id: "em_sealingModule",
          label: $gettext("Sealing Module"),
          description: $gettext("Application of foil to the ring and embossing of the POE"),
          newsCategorySlugs: ["sealing-module"],
          link: "https://soudronic.com/products/peel-off/",
          dotPos: [0.6133474576271186, 0.4764595103578154],
        },
      ],
    },
    "01_machine",
  );

// MULTI-DIE STAMPING PRESS line
endMaking
  .addLine(
    {
      id: "em_multiDieStampingPress",
      label: $gettext("Multi-Die Stamping Press"),
      // TODO: description?
      description: $gettext(`
END-O-MAT Line
- High efficiency multi-die presses for ends and shells 
- Medium and high-speed end production
- End diameter 50 – 153 mm
- Stamping, curling and lining
      `),
      lineDirection: LineDirection.RIGHT_TO_LEFT,
      buttonData: {
        boxPos: [0.4422669491525424, 0.6101694915254238],
        targetPos: [0.6959745762711864, 0.6958568738229756],
      },
    },
    "02_multiDieStampingPress",
  )
  .addStation(
    {
      id: "em_multiDiePress",
      label: $gettext("Multi-Die Press"),
      description: $gettext("The press is designed for multi-die production of lids, EO shells, rings, bottom ends"),
      newsCategorySlugs: ["multi-die-press"],
      link: "https://soudronic.com/products/end-making/?filtered%5Bseries%5D%5B%5D=END-O-MAT&filtered%5Bseries%5D%5B%5D=UNIPRESS",
    },
    "01_multiDiePress",
  )
  .addStation(
    {
      id: "em_curler",
      label: $gettext("Curler"),
      description: $gettext("Stamped ends with an initial curl are processed in a disc curler to round ends"),
      newsCategorySlugs: ["curler"],
      link: "https://soudronic.com/products/end-making/?filtered%5Bapplication%5D%5B%5D=Curling",
    },
    "02_curler",
  )
  .addStation(
    {
      id: "em_liner",
      label: $gettext("Liner"),
      description: $gettext("High-speed rotary liners for all types of compounds applications to the ends"),
      newsCategorySlugs: ["liner"],
      link: "https://soudronic.com/products/end-making/?filtered%5Bapplication%5D%5B%5D=Lining",
    },
    "03_liner",
  )
  .addStation(
    {
      id: "em_dryer",
      label: $gettext("Dryer"),
      description: $gettext("Heating technology to cure water-based end compounds from trusted 3rd party suppliers"),
      newsCategorySlugs: ["dryer"],
      link: "https://soudronic.com/products/end-making/?filtered%5Bseries%5D%5B%5D=END-O-MAT",
    },
    "04_dryer",
  );

endMaking.build();
