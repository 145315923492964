export enum SupplierName {
  SOUDRONIC = "Soudronic",
  CAN_MAN = "Can Man",
  FREI_AG = "Frei AG",
  OCSAM_CEPAK = "Ocsam Cepak",
  SABATIER = "Sabatier",
}

export interface Supplier {
  name: SupplierName;
  shortcut: string;
}

export const useSuppliers = () => {
  const suppliers: { [key in SupplierName]: Supplier } = {
    [SupplierName.SOUDRONIC]: {
      name: SupplierName.SOUDRONIC,
      shortcut: "SAG",
    },
    [SupplierName.CAN_MAN]: {
      name: SupplierName.CAN_MAN,
      shortcut: "CAG",
    },
    [SupplierName.FREI_AG]: {
      name: SupplierName.FREI_AG,
      shortcut: "FAG",
    },
    [SupplierName.OCSAM_CEPAK]: {
      name: SupplierName.OCSAM_CEPAK,
      shortcut: "OCS",
    },
    [SupplierName.SABATIER]: {
      name: SupplierName.SABATIER,
      shortcut: "SSA",
    },
  };

  const getAll = () => {
    return suppliers;
  };

  const getByName = (supplier: SupplierName) => suppliers[supplier];

  return {
    getByName,
    getAll,
  };
};
