import "@/styles/fonts.scss";
import "@/styles/bootstrap.scss";

import { createGtm } from "@gtm-support/vue-gtm";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createApp } from "vue";

import { baseConfig } from "@/core/config";
import { gettext } from "@/core/translations";

import App from "./App.vue";
import router from "./router";

// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
const app = createApp(App);

const pinia = createPinia();
// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
pinia.use(piniaPluginPersistedstate);

app.use(pinia);
app.use(router);
app.use(gettext);
if (baseConfig.VITE_APP_GTM_ENABLED) {
  app.use(
    createGtm({
      id: baseConfig.VITE_APP_GTM_CONTAINER_ID,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: baseConfig.VITE_APP_GTM_DEBUG,
      loadScript: true,
      vueRouter: router,
      ignoredViews: [],
      trackOnNextTick: false,
    }),
  );
}

app.mount("#app");
