import { gettext } from "@/core/translations";
import type { Department } from "@/stores/navigation/nodes";

const { $gettext } = gettext;

export const upgrades: Department = {
  id: "upgrades",
  label: $gettext("Upgrades"),
  description: $gettext(
    "Standardized solutions for upgrades and conversions maintain the value and productivity of your can making lines",
  ),
  url: "https://soudronic.com/services/upgrades/",
};

export const premiumConversions: Department = {
  id: "premium_conversions",
  label: $gettext("Premium Conversions"),
  description: $gettext(
    "Standardized solutions for premium conversions maintain the value and productivity of your can making lines",
  ),
  url: "https://soudronic.com/services/premium-conversions/",
};

export const afterSalesService: Department = {
  id: "after_sales_service",
  label: $gettext("After Sales Service"),
  description: $gettext(
    "Our global after-sales service starts with the delivery of your machine or system and continues for the entire duration of its productive life",
  ),
  url: "https://soudronic.com/services/after-sales-service/",
};

export const spareParts: Department = {
  id: "spare_parts",
  label: $gettext("Spare Parts"),
  description: $gettext(
    "Around the world and around the clock, our customers can rely on a prompt supply of spare parts that reduce downtime to a minimum",
  ),
  url: "https://soudronic.com/services/spareparts/",
};

export const training: Department = {
  id: "training",
  label: $gettext("Training"),
  description: $gettext(
    "All our customer training programs are individually tailored either to specific machines or to entire production lines",
  ),
  url: "https://soudronic.com/services/training/",
};

export const laboratory: Department = {
  id: "laboratory",
  label: $gettext("Laboratory"),
  description: $gettext(
    "Our well-equipped metallurgical laboratory at our headquarters provides various services and ensures highest product quality",
  ),
  url: "https://soudronic.com/services/laboratory/",
};

export const allDepartments: Department[] = [
  afterSalesService,
  spareParts,
  premiumConversions,
  upgrades,
  training,
  laboratory,
];
