const pngUrls = import.meta.glob("../../../assets/media/**/*.png", {
  eager: true,
  as: "url",
});

const mp4Urls = import.meta.glob("../../../assets/media/**/*.mp4", {
  eager: true,
  as: "url",
});

const getPngFromPath = (path: string) => {
  const url = pngUrls[path];
  if (!url) {
    console.warn(`${path} could not be found in media`);
    return null;
  }
  return url;
};

const getMp4FromPath = (path: string) => {
  const url = mp4Urls[path];
  if (!url) {
    console.warn(`${path} could not be found in media`);
    return null;
  }
  return url;
};

export const factoryMedia = (buildingPathName: string) => {
  const buildingPath = `../../../assets/media/${buildingPathName}`;
  const line = (linePathName: string) => {
    const linePath = `${buildingPath}/${linePathName}`;

    return {
      image: (name?: string) => (name ? getPngFromPath(`${linePath}/${name}.png`) : null),
      video: (name?: string) => (name ? getMp4FromPath(`${linePath}/${name}.mp4`) : null),
    };
  };
  return {
    line,
    image: (name?: string) => (name ? getPngFromPath(`${buildingPath}/${name}.png`) : null),
    video: (name?: string) => (name ? getMp4FromPath(`${buildingPath}/${name}.mp4`) : null),
  };
};
