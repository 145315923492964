import { NotFoundGraphError } from "graphology";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { assertIsDefined } from "@/core/utils/typeHelpers";
import router from "@/router";
import { graph } from "@/stores/navigation/graph";
import type {
  Edge,
  EnrichedBuildingNode,
  EnrichedNode,
  EnrichedStationNode,
  GraphNode,
  StationNode,
} from "@/stores/navigation/nodes";
import { EdgeDirection, NodeType } from "@/stores/navigation/nodes";
import { useNews } from "@/stores/news";

export const findNode = (nodeId: string) => {
  return graph.getNodeAttributes(nodeId) as GraphNode;
};

export const useNavigation = defineStore("navigation", () => {
  const news = useNews();

  const introNode = findNode("intro");
  const rootNode = findNode("overview");
  const currentNode = ref<GraphNode>(introNode);
  const previousActiveNode = ref<GraphNode>();
  const completed = ref(true);

  const getRecursiveNewsCategories = (node: GraphNode): string[] =>
    [
      ...(node.nodeType === NodeType.STATION ? node.newsCategorySlugs ?? [] : []),
      ...getNodesInDirection(node.id, EdgeDirection.DOWN).map((n) => getRecursiveNewsCategories(n)),
    ].flat();

  const enrichNode = <T extends GraphNode>(node: T) =>
    computed<{ nodeType: T["nodeType"] } & EnrichedNode>(() => {
      const categories = node.newsCategorySlugs;

      if (node.nodeType === NodeType.BUILDING) {
        const allMaschineNews = news.newsForCategories(
          node.machines?.map((m) => m.newsCategorySlugs ?? []).flat() ?? [],
        ).value;

        const building: EnrichedBuildingNode = {
          ...node,
          news: [...news.newsForCategories(categories).value, ...allMaschineNews],
          machines: node.machines?.map((m) => ({
            ...m,
            news: news.newsForCategories(m.newsCategorySlugs).value,
          })),
          lines: node.lines.map((l) => {
            return {
              ...l,
              news: news.newsForCategories(
                graph
                  .filterNeighbors(node.id, (_neighborId, attrs) => {
                    const neighbor = attrs as GraphNode;
                    if (neighbor.nodeType !== NodeType.STATION) {
                      return false;
                    }
                    return neighbor.lineId === l.id;
                  })
                  .map((nId) => {
                    const node = findNode(nId) as StationNode;
                    const subStationNews = node.subStations?.map((s) => s.newsCategorySlugs ?? []).flat();
                    return [...(findNode(nId).newsCategorySlugs ?? []), ...(subStationNews ?? [])];
                  })
                  .flat(),
              ).value,
            };
          }),
        };
        return building;
      }

      if (node.nodeType == NodeType.STATION) {
        const station: EnrichedStationNode = {
          ...node,
          news: news.newsForCategories(categories).value,
          subStations: node.subStations?.map((s) => ({
            ...s,
            news: news.newsForCategories(s.newsCategorySlugs).value,
          })),
        };
        return station;
      }

      return {
        ...node,
        news: news.newsForCategories(getRecursiveNewsCategories(node)).value,
      };
    });

  const videoEdges = computed(() => {
    if (!currentNode.value) {
      return [];
    }
    const edges = graph
      .filterOutEdges(currentNode.value.id, (_neighborId, attrs) => {
        const edge = attrs as Edge;
        return edge.video !== null;
      })
      .map((edgeId) => graph.getEdgeAttributes(edgeId) as Edge);

    return edges;
  });

  const getNodeInDirection = (nodeId: string, direction: EdgeDirection) => {
    const edges = graph.filterOutEdges(nodeId, (_neighborId, attrs) => {
      const edge = attrs as Edge;
      return edge.direction === direction;
    });
    return edges[0] ? enrichNode(findNode(graph.target(edges[0]))).value : null;
  };
  const getNodesInDirection = (nodeId: string, direction: EdgeDirection) => {
    const edges = graph.filterOutEdges(nodeId, (_neighborId, attrs) => {
      const edge = attrs as Edge;
      return edge.direction === direction;
    });
    return edges.map((eId) => enrichNode(findNode(graph.target(eId))).value);
  };

  const parentNode = computed(() => getNodeInDirection(currentNode.value.id, EdgeDirection.UP));

  const getNthNodeInDirection = (n: number, direction = EdgeDirection.UP) =>
    computed(() => {
      let node: EnrichedNode | null = enrichNode(currentNode.value).value;
      for (let i = 0; i < n; i++) {
        if (!node) {
          return null;
        }
        node = getNodeInDirection(node.id, direction);
      }
      return node;
    });

  const nextNode = computed(() => getNodeInDirection(currentNode.value.id, EdgeDirection.NEXT));
  const previousNode = computed(() => getNodeInDirection(currentNode.value.id, EdgeDirection.PREVIOUS));
  const childNodes = computed(() => getNodesInDirection(currentNode.value.id, EdgeDirection.DOWN));

  const setPath = (fromNodeId: string, toNodeId: string) => {
    completed.value = false;
    previousActiveNode.value = findNode(fromNodeId);
    currentNode.value = findNode(toNodeId);
  };

  const navigateToNode = (nodeId: string) => {
    const node = findNode(nodeId);
    assertIsDefined(node);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    router.push({ name: node.nodeType, params: { nodeId: node.id } });
  };

  const currentNodeAsType = <T extends NodeType>(type: T) =>
    computed(() => {
      if (currentNode.value.nodeType !== type) {
        return null;
      }
      const node = currentNode.value as EnrichedNode & { nodeType: T };
      return enrichNode(node).value;
    });

  const edgeToCurrentNode = computed(() => {
    if (!previousActiveNode.value?.id) {
      return null;
    }
    try {
      return graph.getEdgeAttributes(previousActiveNode.value?.id, currentNode.value.id) as Edge;
    } catch (e) {
      if (e instanceof NotFoundGraphError) {
        return null;
      }
      throw e;
    }
  });

  const overviewNode = currentNodeAsType(NodeType.OVERVIEW);
  const buildingNode = currentNodeAsType(NodeType.BUILDING);
  const stationNode = currentNodeAsType(NodeType.STATION);

  return {
    introNode,
    rootNode,
    setPath,
    currentNode,
    previousActiveNode,
    edgeToCurrentNode,
    videoEdges,
    parentNode,
    getNthNodeInDirection,
    nextNode,
    previousNode,
    childNodes,
    navigateToNode,
    overviewNode,
    buildingNode,
    stationNode,
    completed,
  };
});
