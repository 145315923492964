import { getRuntimeConfig } from "../utils/runtimeData";
import type { RequiredNotNullOrUndefined } from "./../utils/typeHelpers";

// Static values in:
// src/core/config/env.d.ts

type RuntimeConfig = ImportMetaEnv;

const dynamicRuntimeConfig = getRuntimeConfig();

// --- Base Config ---

const defaultBaseConfig: RequiredNotNullOrUndefined<
  Pick<
    RuntimeConfig,
    | "VITE_APP_BASE_URL"
    | "NODE_ENV"
    | "VITE_APP_NEWS_ENDPOINT"
    | "VITE_APP_GTM_CONTAINER_ID"
    | "VITE_APP_GTM_ENABLED"
    | "VITE_APP_GTM_DEBUG"
  >
> = {
  VITE_APP_BASE_URL: "/",
  NODE_ENV: "development",
  VITE_APP_NEWS_ENDPOINT: "https://dev.soudronic.com/app/themes/soudronic/endpoints/wof/news/",
  VITE_APP_GTM_CONTAINER_ID: "",
  VITE_APP_GTM_ENABLED: false,
  VITE_APP_GTM_DEBUG: false,
};

const mergeConfigKey = <
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  T extends keyof typeof defaultBaseConfig & keyof ImportMetaEnv,
>(
  key: T,
) => {
  return (
    dynamicRuntimeConfig[key]
      ? dynamicRuntimeConfig[key]
      : import.meta.env[key]
      ? import.meta.env[key]
      : defaultBaseConfig[key]
  ) as NonNullable<(typeof defaultBaseConfig)[T]>;
};

const rawBaseConfig = {
  VITE_APP_BASE_URL: mergeConfigKey("VITE_APP_BASE_URL"),
  NODE_ENV: mergeConfigKey("NODE_ENV"),
  VITE_APP_NEWS_ENDPOINT: mergeConfigKey("VITE_APP_NEWS_ENDPOINT"),
  VITE_APP_GTM_CONTAINER_ID: mergeConfigKey("VITE_APP_GTM_CONTAINER_ID"),
  VITE_APP_GTM_ENABLED: mergeConfigKey("VITE_APP_GTM_ENABLED"),
  VITE_APP_GTM_DEBUG: mergeConfigKey("VITE_APP_GTM_DEBUG"),
};

const baseConfig = rawBaseConfig;

export { baseConfig };
export type { RuntimeConfig };
