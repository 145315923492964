/*
structure:

          overview
     ┌────────────────┐
     │                │
building1          building2
           ┌───────────────┐
           │               │
         line1           line2
              ┌──────────── ◄──────┐
              ▼                    │
          station1──►station2──►station3

HINT: stations also have parent<->child edges, but these are not considered in the flow if their `video` is null
*/
import { gettext } from "@/core/translations";
import {
  afterSalesService,
  premiumConversions,
  spareParts,
  training,
  upgrades,
} from "@/stores/navigation/graph/departments";
import { graphBuilder } from "@/stores/navigation/graphBuilder";

const { $gettext } = gettext;

const { addBuilding } = graphBuilder;

// PERFORMANCE building
const performance = addBuilding(
  {
    id: "performance",
    label: $gettext("Performance"),
    departments: [
      {
        ...afterSalesService,
        buttonData: {
          boxPos: [0.08002371072910491, 0.3445959296581703],
          targetPos: [0.08002371072910491, 0.4836988737403675],
        },
      },
      {
        ...spareParts,
        buttonData: {
          boxPos: [0.3076467101363367, 0.3445959296581703],
          targetPos: [0.3076467101363367, 0.46999934136863597],
        },
      },
      {
        ...upgrades,
        buttonData: {
          boxPos: [0.4300699466508595, 0.3445959296581703],
          targetPos: [0.4300699466508595, 0.4584074293617862],
        },
      },
      {
        ...premiumConversions,
        buttonData: {
          boxPos: [0.5260699466508595, 0.3445959296581703],
          targetPos: [0.5260699466508595, 0.4584074293617862],
        },
      },
      {
        ...training,
        buttonData: {
          boxPos: [0.6229994072317724, 0.3445959296581703],
          targetPos: [0.6229994072317724, 0.4499769479022591],
        },
      },
    ],
    buttonData: {
      boxPos: [0.053349140486069944, 0.4742145820983995],
      targetPos: [0.3029045643153527, 0.6744385167621683],
    },
    info: $gettext("2,000,000 cans per day"),
    description: $gettext("High-speed 3-Piece Can production machinery. For food, aerosol and industrial products."),
    newsCategorySlugs: ["performance"],
  },
  "01_performance",
);

// FOOD line
performance
  .addLine(
    {
      id: "pf_food",
      label: $gettext("Food Line"),
      description: $gettext(`
- Medium to high-speed production of food or beverage cans
- Up to 1200 cans per minute
- Cylindrical cans or rectangular metal containers
- Diameter from 45 mm – 153 mm
- Height from 50 mm – 230 mm`),
      buttonData: {
        boxPos: [0.4101956135151156, 0.6027794243561878],
        targetPos: [0.3153526970954357, 0.7576895211749983],
      },
    },
    "01_food",
  )
  .addStation(
    {
      id: "pf_slitter",
      label: $gettext("Slitter"),
      description: $gettext("Cutting of printed and lacquered metal sheets into body blanks"),
      link: "https://soudronic.com/products/slitter/?filtered%5Bfactories%5D%5B%5D=Performance",
      newsCategorySlugs: ["slitter"],
    },
    "01_slitter",
  )
  .addStation(
    {
      id: "pf_transferSystem",
      label: $gettext("Transfer System"),
      description: $gettext("Transferring the body blanks from the slitter to the canbody welder"),
      link: "https://soudronic.com/products/transfer/?filtered%5Bfactories%5D%5B%5D=Performance",
      newsCategorySlugs: [],
    },
    "02_transferSystem",
  )
  .addStation(
    {
      id: "pf_canbodyWelder",
      label: $gettext("Canbody Welder"),
      description: $gettext("Forming of the body blanks into a cylindrical shape and joining the edges to a canbody"),
      link: "https://soudronic.com/products/welder/?filtered%5Bfactories%5D%5B%5D=Performance&filtered%5Bapplications%5D%5B%5D=food%2Fbeverage",
      newsCategorySlugs: ["canbody-welder"],
    },
    "03_canbodyWelder",
  )
  .addStation(
    {
      id: "pf_coating",
      label: $gettext("Coating (Seam Protection)"),
      description: $gettext("Application of a protective layer of lacquer or powder to the welded seam"),
      link: "https://soudronic.com/products/coating/",
      newsCategorySlugs: [],
    },
    "04_coating",
  )
  .addStation(
    {
      id: "pf_curingSystem",
      label: $gettext("Curing System"),
      description: $gettext("Designed to cure the inside and outside seam protection layer"),
      link: "https://soudronic.com/products/curing/",
      newsCategorySlugs: ["curing-system"],
    },
    "05_curingSystem",
  )
  .addStation(
    {
      id: "pf_conveyor",
      label: $gettext("Conveyor"),
      description: $gettext("Conveying between machines"),
      link: "https://soudronic.com/products/conveyors/",
      newsCategorySlugs: ["conveyor"],
    },
    "06_conveyor",
  )
  .addStation(
    {
      id: "pf_canAssembling",
      label: $gettext("Can Assembling"),
      description: $gettext("Parting, shaping, necking, flanging, beading and seaming of the canbody"),
      link: "https://soudronic.com/products/can-assembling/?filtered%5Bapplication%5D%5B%5D=Canbody%20Forming%20and%20Assembling&filtered%5Bshape%5D%5B%5D=Round%2C%20dia%2045%20to%20153",
      newsCategorySlugs: ["can-assembling"],
    },
    "07_canAssembling",
  )
  .addStation(
    {
      id: "pf_tester",
      label: $gettext("Tester"),
      description: $gettext("Inline can leak testing"),
      link: "https://soudronic.com/products/tester/?filtered%5Bshape%5D%5B%5D=Round%2C%20dia%2052%20to%20108&filtered%5Bshape%5D%5B%5D=Round%2C%20dia%20108%20to&filtered%5Bheight%5D%5B%5D=H%2035%20to%2089&filtered%5Bheight%5D%5B%5D=H%2090%20to%20134&filtered%5Bheight%5D%5B%5D=H%20135%20to%20249",
      newsCategorySlugs: ["tester"],
    },
    "08_tester",
  );

// AEROSOL line
performance
  .addLine(
    {
      id: "ar_line",
      label: $gettext("Aerosol Line"),
      description: $gettext(`
- Medium to high-speed production of aerosol cans
- 300 – 600 cans per minute
- Diameter from 45 – 70 mm
- Height from 50 – 320 mm`),
      buttonData: {
        boxPos: [0.025489033787788974, 0.6449318316538234],
        targetPos: [0.19205690574985182, 0.582757030889811],
      },
    },
    "02_aerosol",
  )
  .addStation(
    {
      id: "ar_slitter",
      label: $gettext("Slitter"),
      description: $gettext("Cutting of printed and lacquered metal sheets into body blanks"),
      newsCategorySlugs: ["slitter-aerosol-line"],
      link: "https://soudronic.com/products/slitter/?filtered%5Bfactories%5D%5B%5D=Performance",
    },
    "01_slitter",
  )
  .addStation(
    {
      id: "ar_transferSystem",
      label: $gettext("Transfer System"),
      description: $gettext("Transferring the body blanks from the slitter to the canbody welder "),
      newsCategorySlugs: ["transfer-system-aerosol-line"],
      link: "https://soudronic.com/products/transfer/?filtered%5Bfactories%5D%5B%5D=Performance",
    },
    "02_transferSystem",
  )
  .addStation(
    {
      id: "ar_canbodyWelder",
      label: $gettext("Canbody Welder"),
      description: $gettext("Forming of the body blanks into a cylindrical shape and joining the edges to a canbody"),
      newsCategorySlugs: ["canbody-welder-aerosol-line"],
      link: "https://soudronic.com/products/welder/?filtered%5Bfactories%5D%5B%5D=Performance&filtered%5Bapplications%5D%5B%5D=aerosol",
    },
    "03_canbodyWelder",
  )
  .addStation(
    {
      id: "ar_coating",
      label: $gettext("Coating (Seam Protection)"),
      description: $gettext("Application of a protective layer of lacquer or powder to the welded seam"),
      newsCategorySlugs: [],
      link: "https://soudronic.com/products/coating/",
    },
    "04_coating",
  )
  .addStation(
    {
      id: "ar_curingSystem",
      label: $gettext("Curing System"),
      description: $gettext("Designed to cure the inside and outside seam protection layer"),
      newsCategorySlugs: ["curing-system-aerosol-line"],
      link: "https://soudronic.com/products/curing/",
    },
    "05_curingSystem",
  )
  .addStation(
    {
      id: "ar_conveyor",
      label: $gettext("Conveyor"),
      description: $gettext("Conveying between machines"),
      newsCategorySlugs: ["conveyor-aerosol-line"],
      link: "https://soudronic.com/products/conveyors/",
    },
    "06_conveyor",
  )
  .addStation(
    {
      id: "ar_canAssembling",
      label: $gettext("Can Assembling"),
      description: $gettext("Parting, shaping, necking, flanging, beading and seaming of the canbody"),
      newsCategorySlugs: ["can-assembling-aerosol-line"],
      link: "https://soudronic.com/products/can-assembling/?filtered%5Bshape%5D%5B%5D=Round%2C%20dia%2045%20to%20153",
    },
    "07_canAssembling",
  )
  .addStation(
    {
      id: "ar_tester",
      label: $gettext("Tester"),
      description: $gettext("Inline can leak testing"),
      newsCategorySlugs: ["tester-aerosol-line"],
      link: "https://soudronic.com/products/tester/?filtered%5Bshape%5D%5B%5D=Round%2C%20dia%2052%20to%20108&filtered%5Btesting-methode%5D%5B%5D=aerosol",
    },
    "08_tester",
  );

performance.build();
