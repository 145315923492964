import { createGettext } from "vue3-gettext";

import translations from "./translations.json";

export const gettext = createGettext({
  availableLanguages: { en: "English" },
  defaultLanguage: "en",
  translations,
  silent: true,
});
