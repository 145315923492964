import { gettext } from "@/core/translations";
import { afterSalesService, premiumConversions, spareParts, upgrades } from "@/stores/navigation/graph/departments";
import { graphBuilder } from "@/stores/navigation/graphBuilder";

import { SupplierName, useSuppliers } from "../suppliers";

const { $gettext } = gettext;

const { addBuilding } = graphBuilder;
const suppliers = useSuppliers();

const classics = addBuilding(
  {
    id: "classics",
    label: $gettext("Classics"),
    departments: [
      {
        ...upgrades,
        buttonData: {
          boxPos: [0.4523305084745763, 0.0480225988700565],
          targetPos: [0.4523305084745763, 0.1384180790960452],
        },
      },
      {
        ...premiumConversions,
        buttonData: {
          boxPos: [0.523156779661017, 0.0820225988700565],
          targetPos: [0.523156779661017, 0.1384180790960452],
        },
      },
      {
        ...spareParts,
        buttonData: {
          boxPos: [0.583156779661017, 0.11487758945386065],
          targetPos: [0.583156779661017, 0.15725047080979285],
        },
      },
      {
        ...afterSalesService,
        buttonData: {
          boxPos: [0.850635593220339, 0.0480225988700565],
          targetPos: [0.850635593220339, 0.1911487758945386],
        },
      },
    ],
    buttonData: {
      boxPos: [0.7395348837209302, 0.03899097675031285],
      targetPos: [0.7087209302325581, 0.22015503875968992],
    },
    info: $gettext("Upgrades & Conversions"),
    description: $gettext("Maintaining the value and productivity of your long-standing machinery."),
    newsCategorySlugs: ["classics"],
    machines: [
      {
        label: "Can Man Welder",
        description: "Learn more about upgrades and conversions available for Can Man welders",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.CAN_MAN),
            url: "https://soudronic.com/services/upgrades/#welder|can-man",
          },
        ],
        buttonData: {
          boxPos: [0.023587223587223587, 0.33895713895713897],
          targetPos: [0.2800982800982801, 0.2978978978978979],
        },
        newsCategorySlugs: ["can-man-welder"],
      },
      {
        label: "Soudronic AFB Welder",
        description: "There are many upgrades and conversions available for Soudronic's AFB welder series",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#welder|soudronic",
          },
        ],
        buttonData: {
          boxPos: [0.012826837691169216, 0.020172120813462698],
          targetPos: [0.1484029484029484, 0.311001911001911],
        },
        newsCategorySlugs: ["soudronic-afb-welder"],
      },
      {
        label: "Soudronic FBB Welder",
        description: "There are many upgrades and conversions available for Soudronic's FBB welder series",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#welder|soudronic",
          },
        ],
        buttonData: {
          boxPos: [0.252096694622595, 0.7147947157813956],
          targetPos: [0.5002457002457003, 0.38875238875238877],
        },
        newsCategorySlugs: ["soudronic-fbb-welder"],
      },
      {
        label: "Soudronic ABM Welder",
        description: "There are many upgrades and conversions available for Soudronic's ABM welder series",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#welder|soudronic",
          },
        ],
        buttonData: {
          boxPos: [0.3542997542997543, 0.8369096369096369],
          targetPos: [0.597051597051597, 0.6307398307398308],
        },
        newsCategorySlugs: ["soudronic-abm-welder"],
      },
      {
        label: "Soudronic VAA Welder",
        description: "There are many upgrades and conversions available for Soudronic's VAA welder series",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#welder|soudronic",
          },
        ],
        buttonData: {
          boxPos: [0.20966946225949679, 0.0964753604122129],
          targetPos: [0.3262899262899263, 0.20966420966420968],
        },
        newsCategorySlugs: ["soudronic-vaa-welder"],
      },
      {
        label: "Welder",
        description:
          "In case you haven't found your welder yet, please have a look at the overview of all upgrades and conversions",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#welder|soudronic",
          },
        ],
        buttonData: {
          boxPos: [0.6044226044226044, 0.2612066612066612],
          targetPos: [0.4697788697788698, 0.29964509964509967],
        },
        newsCategorySlugs: ["welder"],
      },
      {
        label: "Curing",
        description: "Learn more about upgrades and conversions available for curing systems",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#curing|soudronic",
          },
          {
            supplier: suppliers.getByName(SupplierName.CAN_MAN),
            url: "https://soudronic.com/services/upgrades/#curing|can-man",
          },
          {
            supplier: suppliers.getByName(SupplierName.FREI_AG),
            url: "https://soudronic.com/services/upgrades/#curing|frei-ag",
          },
        ],
        buttonData: {
          boxPos: [0.6343980343980344, 0.3835107835107835],
          targetPos: [0.600982800982801, 0.3354627354627355],
        },
        newsCategorySlugs: ["curing"],
      },
      {
        label: "Slitter",
        description: "Learn more about upgrades and conversions available for slitters",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.OCSAM_CEPAK),
            url: "https://soudronic.com/services/upgrades/#slitter|ocsam-cepak",
          },
          {
            supplier: suppliers.getByName(SupplierName.CAN_MAN),
            url: "https://soudronic.com/services/upgrades/#slitter|can-man",
          },
        ],
        buttonData: {
          boxPos: [0.05031779661016949, 0.6064030131826742],
          targetPos: [0.2288135593220339, 0.4962335216572505],
        },
        newsCategorySlugs: ["slitter-classics"],
      },
      {
        label: "Coating (Seam Protection)",
        description: "Learn more about upgrades and conversions available for seam protection systems",
        suppliers: [
          {
            supplier: suppliers.getByName(SupplierName.SOUDRONIC),
            url: "https://soudronic.com/services/upgrades/#coating|soudronic",
          },
          {
            supplier: suppliers.getByName(SupplierName.FREI_AG),
            url: "https://soudronic.com/services/upgrades/#coating|frei-ag",
          },
        ],
        buttonData: {
          boxPos: [0.3882063882063882, 0.18695058695058694],
          targetPos: [0.36855036855036855, 0.32585312585312587],
        },
        newsCategorySlugs: ["coating-seam-protection-classics"],
      },
    ],
  },
  "05_classics",
);

classics.build();
