import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";

import { useNavigation } from "@/stores/navigation/navigation";
import { NodeType } from "@/stores/navigation/nodes";

export enum MainRoutes {
  JOURNEY = "JOURNEY",
}

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: { name: MainRoutes.JOURNEY },
  },
  {
    path: "/factories",
    name: MainRoutes.JOURNEY,
    component: () => import("./views/JourneyView.vue"),
    redirect: { name: NodeType.OVERVIEW },
    children: [
      {
        path: "/factories/overview",
        name: NodeType.OVERVIEW,
        component: () => import("./views/OverviewView.vue"),
      },
      {
        path: "/factories/building/:nodeId",
        name: NodeType.BUILDING,
        component: () => import("./views/BuildingView.vue"),
        props: true,
      },
      {
        path: "/factories/station/:nodeId",
        name: NodeType.STATION,
        component: () => import("./views/StationView.vue"),
        props: true,
      },
    ],
  },
];

// TODO: move into DEV-block again
routes.push({
  path: "/graph",
  name: "_graph",
  component: () => import("./views/dev/GraphViz.vue"),
});

if (import.meta.env.DEV === true) {
  routes.push({
    path: "/dev",
    name: "_dev",
    component: () => import("./views/dev/DevView.vue"),
  });
}

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  if (!to.path.startsWith("/factories")) {
    next();
    return;
  }
  const nav = useNavigation();
  const nextNodeId = !to.params.nodeId || typeof to.params.nodeId !== "string" ? nav.rootNode.id : to.params.nodeId;
  nav.setPath(nav.currentNode.id, nextNodeId);
  next();
});

export default router;
