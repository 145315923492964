import { gettext } from "@/core/translations";
import {
  afterSalesService,
  laboratory,
  premiumConversions,
  spareParts,
  training,
  upgrades,
} from "@/stores/navigation/graph/departments";
import { graphBuilder } from "@/stores/navigation/graphBuilder";

const { $gettext } = gettext;

const { addBuilding } = graphBuilder;

const flexibility = addBuilding(
  {
    id: "flexibility",
    label: $gettext("Flexibility"),
    departments: [
      {
        ...afterSalesService,
        buttonData: {
          boxPos: [0.2155720338983051, 0.2937853107344633],
          targetPos: [0.2155720338983051, 0.455743879472693],
        },
      },
      {
        ...spareParts,
        buttonData: {
          boxPos: [0.3432203389830508, 0.3709981167608286],
          targetPos: [0.3432203389830508, 0.4529190207156309],
        },
      },
      {
        ...upgrades,
        buttonData: {
          boxPos: [0.4595338983050848, 0.2937853107344633],
          targetPos: [0.4595338983050848, 0.4472693032015066],
        },
      },
      {
        ...premiumConversions,
        buttonData: {
          boxPos: [0.5554025423728814, 0.3709981167608286],
          targetPos: [0.5554025423728814, 0.4453860640301318],
        },
      },
      {
        ...training,
        buttonData: {
          boxPos: [0.6477754237288136, 0.2937853107344633],
          targetPos: [0.6477754237288136, 0.4444444444444444],
        },
      },
      {
        ...laboratory,
        buttonData: {
          boxPos: [0.6877754237288136, 0.3709981167608286],
          targetPos: [0.6877754237288136, 0.4344444444444444],
        },
      },
    ],
    buttonData: {
      boxPos: [0.7018375815056312, 0.4742145820983995],
      targetPos: [0.6297669491525424, 0.6534839924670434],
    },
    info: $gettext("Format changeovers in 5 minutes"),
    description: $gettext(
      "3-Piece, low to medium speed production machinery for all shapes and sizes of cans. For food, aerosol and industrial products.",
    ),
    newsCategorySlugs: [],
  },
  "02_flexibility",
);

flexibility
  .addLine(
    {
      id: "fl_food",
      label: $gettext("Food/Aerosol Line"),
      description: $gettext(`
- Low to medium speed production of food or beverage cans 
- Up to 600 cans per minute 
- Cylindrical cans and rectangular shapes, oblong or square metal containers 
- Diameter from 45 – 200 mm 
- Height from 50 – 320 mm`),
      buttonData: {
        boxPos: [0.5010593220338984, 0.5903954802259888],
        targetPos: [0.3893008474576271, 0.7137476459510358],
      },
    },
    "01_food",
  )
  .addStation(
    {
      id: "fl_slitter",
      label: $gettext("Slitter"),
      description: $gettext("Cutting of printed and lacquered metal sheets into body blanks"),
      newsCategorySlugs: ["slitter-food-line-flexibility"],
      link: "https://soudronic.com/products/slitter/?filtered%5Bfactories%5D%5B%5D=Flexibility&filtered%5Bseries%5D%5B%5D=PowerCUT&load=9673",
    },
    "01_slitter",
  )
  .addStation(
    {
      id: "fl_transferSystem",
      label: $gettext("Transfer System with Storage"),
      description: $gettext("Transferring the body blanks from the slitter to the canbody welder"),
      newsCategorySlugs: ["transfer-system-with-storage"],
      link: "https://soudronic.com/products/transfer/?filtered%5Bfactories%5D%5B%5D=Flexibility&filtered%5Bseries%5D%5B%5D=PowerSTORE",
    },
    "02_transferSystem",
  )
  .addStation(
    {
      id: "fl_canbodyWelder",
      label: $gettext("Canbody Welder"),
      description: $gettext("Forming of the body blanks into a cylindrical shape and joining the edges to a canbody"),
      newsCategorySlugs: [],
      link: "https://soudronic.com/products/welder/?filtered%5Bfactories%5D%5B%5D=Flexibility&filtered%5Bapplications%5D%5B%5D=food%2Fbeverage",
    },
    "03_canbodyWelder",
  )
  .addStation(
    {
      id: "fl_coating",
      label: $gettext("Coating (Seam Protection)"),
      description: $gettext("Application of a protective layer of lacquer or powder to the welded seam"),
      newsCategorySlugs: ["coating-seam-protection-food-line-flexibility"],
      link: "https://soudronic.com/products/coating/",
    },
    "04_coating",
  )
  .addStation(
    {
      id: "fl_curingSystem",
      label: $gettext("Curing System"),
      description: $gettext("Designed to cure the inside and outside seam protection layer"),
      newsCategorySlugs: ["curing-system-food-line-flexibility"],
      link: "https://soudronic.com/products/curing/?filtered%5Bseries%5D%5B%5D=PowerCURE%202",
    },
    "05_curingSystems",
  )
  .addStation(
    {
      id: "fl_conveyor",
      label: $gettext("Conveyor"),
      description: $gettext("Conveying between machines"),
      newsCategorySlugs: ["conveyor-food-line-flexibility"],
      link: "https://soudronic.com/products/conveyors/",
    },
    "06_conveyor",
  )
  .addStation(
    {
      id: "fl_canAssembling",
      label: $gettext("Can Assembling"),
      description: $gettext("Parting, shaping, necking, flanging, beading and seaming of the canbody"),
      newsCategorySlugs: [],
      link: "https://soudronic.com/products/can-assembling/?filtered%5Bapplication%5D%5B%5D=Canbody%20Forming%20and%20Assembling&filtered%5Bseries%5D%5B%5D=Bodypack&filtered%5Bseries%5D%5B%5D=Bodyshaper&filtered%5Bseries%5D%5B%5D=CAN-O-MAT%20%E2%85%A0&filtered%5Bseries%5D%5B%5D=CAN-O-MAT%20%E2%85%A1",
    },
    "07_canAssembling",
  )
  .addStation(
    {
      id: "fl_tester",
      label: $gettext("Tester"),
      description: $gettext("Inline can leak testing"),
      newsCategorySlugs: ["tester-industrial-line"],
      link: "https://soudronic.com/products/tester/?filtered%5Btesting-methode%5D%5B%5D=pressure%20tester%20with%20volume%20reducer&filtered%5Btesting-methode%5D%5B%5D=pressure%20tester%20without%20volume%20reducer&filtered%5Btesting-methode%5D%5B%5D=vacuum%20tester",
    },
    "08_tester",
  );

// INDUSTRIAL line
flexibility
  .addLine(
    {
      id: "in_line",
      label: $gettext("Industrial Line"),
      description: $gettext(`
- Low to medium speed production of general line cans 
- Up to 180 cans per minute 
- Various shapes and sizes: large cylindrical cans and conical, rectangular, oblong or square metal containers 
- Diameter from 45 mm – 450 mm 
- Height from 50 mm – 900 mm`),
      buttonData: {
        boxPos: [0.0625, 0.635593220338983],
        targetPos: [0.2997881355932203, 0.6026365348399246],
      },
    },
    "02_industrial",
  )
  .addStation(
    {
      id: "in_slitter",
      label: $gettext("Slitter"),
      description: $gettext("Cutting of printed and lacquered metal sheets into body blanks"),
      newsCategorySlugs: ["slitter-industrial-line"],
      link: "https://soudronic.com/products/slitter/?filtered%5Bfactories%5D%5B%5D=Flexibility&filtered%5Bseries%5D%5B%5D=PowerCUT&filtered%5Bseries%5D%5B%5D=Single%20Slitter",
    },
    "01_slitter",
  )
  .addStation(
    {
      id: "in_canbodyWelder",
      label: $gettext("Canbody Welder"),
      description: $gettext("Forming of the body blanks into a cylindrical shape and joining the edges to a canbody"),
      newsCategorySlugs: ["canbody-welder-industrial-line"],
      link: "https://soudronic.com/products/welder/?filtered%5Bfactories%5D%5B%5D=Flexibility&filtered%5Bapplications%5D%5B%5D=industrial",
    },
    "02_canbodyWelder",
  )
  .addStation(
    {
      id: "in_coating",
      label: $gettext("Coating (Seam Protection)"),
      description: $gettext("Optional: Application of a protective layer of lacquer or powder to the welded seam"),
      newsCategorySlugs: ["coating-seam-protection-industrial-line-flexibility"],
      link: "https://soudronic.com/products/coating/",
    },
    "03_keineBeschriftung",
  )
  .addStation(
    {
      id: "in_canAssembling",
      label: $gettext("Can Assembling"),
      description: $gettext("Parting, shaping, necking, flanging, beading and seaming of the canbody"),
      newsCategorySlugs: ["can-assembling-industrial-line"],
      link: "https://soudronic.com/products/can-assembling/?filtered%5Bapplication%5D%5B%5D=Canbody%20Forming%20and%20Assembling&filtered%5Bseries%5D%5B%5D=Bodypack&filtered%5Bseries%5D%5B%5D=Squarepack&filtered%5Bseries%5D%5B%5D=Bodyshaper",
    },
    "04_canAssembling",
  )
  .addStation(
    {
      id: "in_tester",
      label: $gettext("Tester"),
      description: $gettext("Inline can leak testing"),
      newsCategorySlugs: ["tester-industrial-line"],
      link: "https://soudronic.com/products/tester/?filtered%5Btesting-methode%5D%5B%5D=pressure%20tester%20with%20volume%20reducer&filtered%5Btesting-methode%5D%5B%5D=pressure%20tester%20without%20volume%20reducer&filtered%5Btesting-methode%5D%5B%5D=vacuum%20tester&filtered%5Bseries%5D%5B%5D=Bodypack%20Tester&filtered%5Bseries%5D%5B%5D=Squarepack%20Tester",
    },
    "05_tester",
  )
  .addStation(
    {
      id: "in_earWeldProtection",
      label: $gettext("Ear Weld Protection"),
      description: $gettext("Application of protective layer and curing"),
      newsCategorySlugs: ["ear-weld-protection"],
      link: "https://soudronic.com/products/curing/?filtered%5Bseam%5D%5B%5D=Ear",
    },
    "06_earWeldProtection",
  )
  .addStation(
    {
      id: "in_stacker",
      label: $gettext("Stacker"),
      description: $gettext("Stacking of the finished containers"),
      newsCategorySlugs: ["stacker"],
      link: "https://soudronic.com/products/palletizer/?filtered%5Bapplication%5D%5B%5D=Stacker",
    },
    "07_stacker",
  );

flexibility.build();
